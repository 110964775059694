<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{ title }} grade</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" sm="6" md="12">
              <v-text-field 
                outlined 
                dense 
                :error="$v.grade.name.$error"
                v-model="grade.name"
              >
                <template v-slot:label>
                  Grade name <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.name" >{{errors.name[0]}}</span>
              <span class="text-danger" v-if="$v.grade.name.$error">This information is required</span>
            </v-col>

            <v-col cols="12" sm="6" md="12">
              <v-text-field
                  label="Short name "
                  v-model="grade.short_name"
                  outlined
                  dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              Status
              <v-switch
                :error="$v.grade.is_active.$error"
                v-model="grade.is_active"
                :label="grade.is_active ? 'Active':'Inactive' "
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            text
            x-large
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            dark
            x-large
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import GradeService from "@/services/product/grade/GradeService";
import {required, email, requiredIf} from "vuelidate/lib/validators";

const grade = new GradeService();

export default {
  validations: {
    grade: {
      name:{required},
      is_active: {required},
      short_name: {},
    }
  },
  data() {
    return {
      title: "",
      edit: false,
      errors: [],
      dialog: false,
      loading: false,
      grade: {
        name:'',
        short_name:'',
        is_active: 1,
      }
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
    },
    openDialog() {
      this.$v.$reset();
      this.dialog = true;
    },
    createGrade() {
      this.resetForm();
      this.openDialog();
      this.edit = false;
      this.title = "Add";
    },
    editGrade(item) {
      this.openDialog();
      this.edit = true;
      this.grade = item;
      this.title = "Edit";
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } 
      else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {
      this.loading = true;
      grade
      .create(this.grade)
      .then(response => {
        this.$snotify.success("Grade added");
        this.resetForm();
        this.closeDialog();
        this.loading = false;
        this.$emit('refresh');
      })
      .catch((err) => {
        this.loading = false;
        this.errors = err.errors;
        this.$snotify.error("Oops something went wrong");
      });
    },
    update: function () {
      this.loading = true;
      grade
      .update(this.grade.id, this.grade)
      .then(response => {
        this.$snotify.success("Grade updated");
        this.resetForm();
        this.closeDialog();
        this.loading = false;
        this.$emit('refresh');
      })
      .catch((err) => {
        this.loading = false;
        this.errors = err.errors;
        this.$snotify.error("Oops something went wrong");
      });
    },
    resetForm() {
      this.grade = {
        name:'',
        short_name: '',
        is_active: 1,
      };
      this.errors=[];
      this.$v.$reset();
    },
  }
}
</script>