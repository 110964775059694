<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Grade
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Grade
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      @click="createGrade()"
                      class="btn btn-primary font-weight-bolder font-size-sm"
                      style="color: #fff;">
                    <i class="fa fa-plus"></i>
                    Add grade
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="5">
                  <v-text-field
                    label="Grade title"
                    v-model="search.name"
                    outlined
                    dense
                    @input="search.name = $event !== null ? $event : ''"
                    clearable
                    v-on:keyup.enter="searchGrades()"
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="5">
                  <v-select
                    :items="search_statuses"
                    v-model="search.status"
                    label="Status"
                    item-text="name"
                    item-value="value"
                    outlined
                    dense
                    clearable
                    v-on:keyup.enter="searchGrades()"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="2">
                  <v-btn @click="searchGrades()"
                    :loading="loading"
                    class="btn btn-primary btn-block w-35 float-right"
                    dark
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>


            <!-- table starts -->
            <div class="table-responsive">
              <v-skeleton-loader
                type="table-thead"
                v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                v-if="loading"
                type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table" v-if="! loading">
                <thead>
                  <tr class="px-3">
                    <th class="px-3">Grade</th>
                    <th class="px-3">Status</th>
                    <th class="pr-3 text-center">Options</th>
                  </tr>
                </thead>

                <template>
                  <draggable
                    v-model="grades"
                    @change="sort"
                    @start="drag=true"
                    tag="tbody"
                    @end="drag=false"
                  >
                    <tr v-for="(item, index) in grades" :key="index">
                      <td class="px-3" style="max-width: 140px !important; white-space: pre-wrap;">
                        <a href="#" class="text-primary ml-2 font-weight-bolder d-block font-size-lg" @click="editGrade(item)">
                          <i class="fa fa-sort"></i> {{ item.name }}
                        </a>
                      </td>
                      <td class="px-3">
                        <span class="badge"
                          v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"
                        >{{ item.is_active ? 'Active' : 'Inactive' }}</span>
                      </td>
                      <td class="pr-0 text-center">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item" v-if="currentUser.access_type=='federal'">
                                <a @click="editGrade(item)" class="navi-link">
                                  <span class="navi-icon">
                                      <i class="fas fa-edit"></i>
                                  </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click.prevent="deleteGrade(item.id)">
                                  <span class="navi-icon">
                                      <i class="fas fa-trash"></i>
                                  </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="grades.length == 0">
                      <td class="text-center px-3" colspan="4">No items found.</td>
                    </tr>
                  </draggable>
                </template>
              </table>
            </div>

            <v-overlay :value="isSorting">
              <v-progress-circular
                indeterminate
                color="dark"
              ></v-progress-circular>
            </v-overlay>
          </div>
        </div>
      </div>

      <create-and-update ref="create-and-update" @refresh="getAllGrades"></create-and-update>
    </div>
  </v-app>
</template>
<script>
import GradeService from "@/services/product/grade/GradeService";
import CreateAndUpdate from "./CreateAndUpdate";
import draggable from 'vuedraggable';

const grade = new GradeService();

export default {
  components: {
    CreateAndUpdate, draggable
  },
  data(){
    return{
      grades:[],
      total: null,
      perPage: null,
      page: null,
      loading: false,
      isSorting: false,
      dialog: false,
      search:{
        status:'',
        name:'',
      },
      currentUser:{},
      search_statuses: [
        {name: 'Active', value: 1},
        {name: 'Inactive', value: 0},
      ],
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.errors = [];
    },
    openDialog() {
      this.dialog = true;
    },
    createGrade() {
      this.$refs['create-and-update'].createGrade();
    },
    editGrade(item) {
      this.$refs['create-and-update'].editGrade(item);
    },
    getAllGrades(){
      this.loading = true;
      grade
      .paginate(this.search, this.page)
      .then(response => {
        this.grades=response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
      });
    },
    searchGrades(){
      this.getAllGrades();
    },
    deleteGrade(gradeId) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            grade
            .delete(gradeId)
            .then((response) => {
              this.getAllGrades();
              this.$snotify.success("Grade Deleted Sucessfully ");
            })
            .catch((err) => {
              this.$snotify.error("Oops something went wrong");
            });
          }
        },
      });
    },
    sort(){
      this.isSorting = true;
      grade
      .sort(this.grades)
      .then(response => {
        this.getAllGrades();
        this.isSorting = false;
        this.$snotify.success('Grade Sorted!!');
      })
      .catch(err => {
        this.isSorting = false;
        this.$snotify.error("Oops something went wrong");
      });
    },
    getCurrentUser() {
      this.currentUser =  this.$store.getters.currentUser;
    }
  },
  computed:{
  },
  mounted() {
    this.getAllGrades();
    this.getCurrentUser();
  }
}
</script>
<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>
